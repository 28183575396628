function cookieCnil(){
    if ($.cookie('cookieCnil') === undefined) {
        $('#cookieCnil').fadeIn();
        $('#cookie_btn_ok').click(function(e){
            e.preventDefault();
            $('#cookieCnil').fadeOut();
            $.cookie('cookieCnil', "viewed", {expires: 365});
        });

        $('#cookie_btn_refuser').click(function(e){
            e.preventDefault();
            $('#cookieCnil').fadeOut();
            $.cookie('cookieCnil', "viewed", {expires: 365});
            $.cookie('cookieRefuser', "1", {expires: 365});
        });
    }
}

$(function () {
    cookieCnil(); // Cookie CNIL

    //CONTACT FORM
    $('.contact-message textarea, .these-message textarea').focus(function(){
        $(this).css("height", "195px");
    });

    //MENU RESPONSIVE
    $('#navButtonRwd').click(function(e){
        e.preventDefault();
        $(this).toggleClass('menu__btn--opened');
        $('.menu__list').toggleClass('menu__nav--opened');
        $('#nav').toggleClass('menu--opened');
    });
});


//FONCTION AFFIX MENU

var offset = 70;

    function affix() {

        if (window.matchMedia("(min-width: 992px)").matches) {
            // Fit affix width to its parent's width
            var $affixElement = $('div[data-spy="affix"]');
            $affixElement.width($affixElement.parent().width());

        }
        else {
            $('div[data-spy="affix"]').width('100%');
        }
    }

    // Call to function on DOM ready
    affix();

    // Call on scroll or resize
    $(window).on('scroll resize', function() {
        affix();

    });

    //FIXED AFTER ANIMATION
    setInterval(function(){
        $(".sidebar").css({"position": "fixed", "animation-fill-mode": "none", "opacity": "1"});
    }, 1000);


